import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-[#20394a] hover:text-[#66caf4]" smooth to="/#about">
                Nous connaître
            </HashLink>
            <HashLink className="px-4 font-extrabold text-[#20394a] hover:text-[#66caf4]" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" to="/contact#contact">
                Contactez-nous
            </HashLink>
        </>
    )
}

export default NavLinks;
