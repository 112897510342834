import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {

    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Zone supérieure : Blocs */}
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-5 py-8 md:py-12 border-t border-gray-200">

                        {/* Premier bloc */}
                        <div className="flex flex-col items-center">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LIENS</h6>
                            <ul className="text-md font-bold">
                                <li className="mb-2">
                                    <HashLink to="/#about" className="text-[#20394a] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">À propos</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-[#20394a] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/contact#contact" className="text-[#20394a] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* Deuxième bloc */}
                        <div className="flex flex-col items-center">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">NOS SERVICES</h6>
                            <ul className="text-md font-bold">
                                <li className="mb-2">
                                    <p className="text-[#20394a] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Développement Web</p>
                                </li>
                                <li className="mb-2">
                                    <p className="text-[#20394a] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Domaines et Hébergement</p>
                                </li>
                            </ul>
                        </div>

                        {/* Troisième bloc */}
                        <div className="flex flex-col items-center">
                            <div className="text-xl mb-6 font-bold uppercase text-[#20394a]">
                                Liens Réseaux Sociaux
                            </div>

                            <div className="text-md mb-6 font-bold">
                                Suivez-nous sur les réseaux sociaux.
                            </div>
                            <div className="flex justify-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link to="#" className="flex justify-center items-center text-[#20394a] hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-[#20394a] hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                            </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    {/* Bas de page */}
                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-700 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-gray-900"
                                >
                                    Anatix
                                </HashLink>. Tous droits réservés.
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    );
}

export default Footer;
